.container > * + * {
  margin-top: 4.8rem;
}
.container .title {
  margin-bottom: 1.6rem;
}
.container .primary-description {
  max-width: 100%;
}
.container .free-topic-page-link {
  margin-top: 0.8rem;
}
