@container ud-content-area (min-width: @content-area-sm-min) {
  /* stylelint-disable-next-line max-line-length */
  grid-auto-columns: calc((100% - (4 - 1) * 1.6rem) * (3 / 4) + (3 - 1) * 1.6rem);
}@container ud-content-area (min-width: @content-area-md-min) {
  /* stylelint-disable-next-line max-line-length */
  grid-auto-columns: calc((100% - (12 - 1) * 1.6rem) * (6 / 12) + (6 - 1) * 1.6rem);
}@container ud-content-area (min-width: @content-area-xl-min) {
  /* stylelint-disable-next-line max-line-length */
  grid-auto-columns: calc((100% - (12 - 1) * 1.6rem) * (4 / 12) + (4 - 1) * 1.6rem);
}@container ud-content-area (min-width: @content-area-xl-min) {
  /* stylelint-disable-next-line max-line-length */
  grid-auto-columns: calc((100% - (9 - 1) * 1.6rem) * (4 / 9) + (4 - 1) * 1.6rem);
}@container ud-content-area (min-width: @content-area-xl-mid) {
  /* stylelint-disable-next-line max-line-length */
  grid-auto-columns: calc((100% - (9 - 1) * 1.6rem) * (3 / 9) + (3 - 1) * 1.6rem);
}@container ud-content-area (min-width: @content-area-sm-min) {
  grid-auto-columns: calc((100% - (2 - 1) * 1.6rem) / 2);
}@container ud-content-area (min-width: @content-area-md-min) {
  grid-auto-columns: calc((100% - (3 - 1) * 1.6rem) / 3);
}@container ud-content-area (min-width: @content-area-lg-min) {
  grid-auto-columns: calc((100% - (4 - 1) * 1.6rem) / 4);
}@container ud-content-area (min-width: @content-area-sm-min) {
  grid-auto-columns: calc((100% - (2 - 1) * 1.6rem) / 2);
}@container ud-content-area (min-width: @content-area-md-min) {
  grid-auto-columns: calc((100% - (3 - 1) * 1.6rem) / 3);
}@container ud-content-area (min-width: @content-area-lg-min) {
  grid-auto-columns: calc((100% - (4 - 1) * 1.6rem) / 4);
}@container ud-content-area (min-width: @content-area-xl-min) {
  grid-auto-columns: calc((100% - (5 - 1) * 1.6rem) / 5);
}.certification-unit-container h2 {
  max-width: 100%;
}
.certification-carousel {
  margin-top: 1.6rem;
  margin-bottom: 2.4rem;
}
@media (min-width: 37.5625rem) {
  .certification-carousel {
    margin-top: 2.4rem;
    grid-auto-columns: calc((100% - (2 - 1) * 1.6rem) / 2);
  }
  .certification-carousel a {
    width: unset;
  }
}
@media (min-width: 75.0625rem) {
  .certification-carousel {
    grid-auto-columns: calc((100% - (3 - 1) * 1.6rem) / 3);
  }
}
@media (min-width: 37.5625rem) {
  .certification-unit-container h2:not([class*='serif']) {
    font-size: clamp(2rem, calc(2rem + (2.4 - 2) * calc((100vw - 36rem) / (144 - 36))), 2.4rem);
  }
  .certification-unit-container h2[class*='serif'] {
    font-family: var(--font-stack-heading-serif);
    font-weight: 700;
    font-size: clamp(2.4rem, calc(2.4rem + (3.2 - 2.4) * calc((100vw - 36rem) / (144 - 36))), 3.2rem);
    line-height: 1.25;
    letter-spacing: -0.016rem;
    max-width: 36em;
  }
}
.carousel-card-container {
  border: 1px solid #d1d2e0;
  display: flex;
  height: 14rem;
}
@media (max-width: 37.5rem) {
  .carousel-card-container {
    width: 85vw;
  }
}
.carousel-card-link {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.carousel-info {
  color: #595c73;
  flex: 1;
  display: flex;
  flex-direction: column;
  min-width: 0;
  justify-content: space-between;
  margin-right: 0.8rem;
}
.carousel-certificate-image {
  margin-right: 0.8rem;
  margin-left: 0.8rem;
  height: 10.8rem;
  width: 10.8rem;
}
.carousel-issuer-name {
  margin-top: 0.8rem;
  color: #595c73;
}
