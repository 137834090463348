.fill-background {
  background-color: #f6f7f9;
}
.nav-breadcrumbs {
  padding-top: 1.6rem;
}
.no-padding-on-left-and-right {
  padding-left: 0;
  padding-right: 0;
}
