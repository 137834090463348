@container ud-content-area (min-width: @content-area-sm-min) {
  /* stylelint-disable-next-line max-line-length */
  grid-auto-columns: calc((100% - (4 - 1) * 1.6rem) * (3 / 4) + (3 - 1) * 1.6rem);
}@container ud-content-area (min-width: @content-area-md-min) {
  /* stylelint-disable-next-line max-line-length */
  grid-auto-columns: calc((100% - (12 - 1) * 1.6rem) * (6 / 12) + (6 - 1) * 1.6rem);
}@container ud-content-area (min-width: @content-area-xl-min) {
  /* stylelint-disable-next-line max-line-length */
  grid-auto-columns: calc((100% - (12 - 1) * 1.6rem) * (4 / 12) + (4 - 1) * 1.6rem);
}@container ud-content-area (min-width: @content-area-xl-min) {
  /* stylelint-disable-next-line max-line-length */
  grid-auto-columns: calc((100% - (9 - 1) * 1.6rem) * (4 / 9) + (4 - 1) * 1.6rem);
}@container ud-content-area (min-width: @content-area-xl-mid) {
  /* stylelint-disable-next-line max-line-length */
  grid-auto-columns: calc((100% - (9 - 1) * 1.6rem) * (3 / 9) + (3 - 1) * 1.6rem);
}@container ud-content-area (min-width: @content-area-sm-min) {
  grid-auto-columns: calc((100% - (2 - 1) * 1.6rem) / 2);
}@container ud-content-area (min-width: @content-area-md-min) {
  grid-auto-columns: calc((100% - (3 - 1) * 1.6rem) / 3);
}@container ud-content-area (min-width: @content-area-lg-min) {
  grid-auto-columns: calc((100% - (4 - 1) * 1.6rem) / 4);
}@container ud-content-area (min-width: @content-area-sm-min) {
  grid-auto-columns: calc((100% - (2 - 1) * 1.6rem) / 2);
}@container ud-content-area (min-width: @content-area-md-min) {
  grid-auto-columns: calc((100% - (3 - 1) * 1.6rem) / 3);
}@container ud-content-area (min-width: @content-area-lg-min) {
  grid-auto-columns: calc((100% - (4 - 1) * 1.6rem) / 4);
}@container ud-content-area (min-width: @content-area-xl-min) {
  grid-auto-columns: calc((100% - (5 - 1) * 1.6rem) / 5);
}.lab-unit-container h2 {
  max-width: 100%;
}
.subtitle-button-wrapper {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 0.8rem;
}
.subtitle-button-wrapper .unit-subtitle {
  margin-bottom: 0.8rem;
  margin-right: 1.6rem;
  max-width: 100%;
}
.subtitle-button-wrapper .browse-button {
  display: none;
}
@media (min-width: 37.5625rem) {
  .subtitle-button-wrapper {
    flex-direction: row;
  }
  .subtitle-button-wrapper .unit-subtitle {
    margin-bottom: 0;
  }
  .subtitle-button-wrapper .browse-button:not(.is-standalone-unit) {
    display: inline;
  }
}
.lab-carousel {
  margin-top: 1.6rem;
  margin-bottom: 2.4rem;
}
.lab-carousel a {
  height: 15rem;
  width: 31.9rem;
}
@media (min-width: 37.5625rem) {
  .lab-carousel {
    margin-top: 2.4rem;
    grid-auto-columns: calc((100% - (2 - 1) * 1.6rem) / 2);
  }
  .lab-carousel a {
    width: unset;
  }
}
@media (min-width: 75.0625rem) {
  .lab-carousel {
    grid-auto-columns: calc((100% - (3 - 1) * 1.6rem) / 3);
  }
}
.browse-button {
  display: inline;
  min-width: fit-content;
}
@media (min-width: 37.5625rem) {
  .browse-button:not(.is-standalone-unit) {
    display: none;
  }
  .lab-unit-container h2:not([class*='serif']) {
    font-size: clamp(2rem, calc(2rem + (2.4 - 2) * calc((100vw - 36rem) / (144 - 36))), 2.4rem);
  }
  .lab-unit-container h2[class*='serif'] {
    font-family: var(--font-stack-heading-serif);
    font-weight: 700;
    font-size: clamp(2.4rem, calc(2.4rem + (3.2 - 2.4) * calc((100vw - 36rem) / (144 - 36))), 3.2rem);
    line-height: 1.25;
    letter-spacing: -0.016rem;
    max-width: 36em;
  }
}
