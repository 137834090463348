.wrapper {
  background: #f6f7f9;
  display: inline-block;
  padding: 0;
}
.header {
  padding: 2.4rem;
}
.view-more-button {
  display: flex;
  flex-direction: column;
  white-space: normal;
}
.module-title {
  margin-top: 0;
  margin-bottom: 1.6rem;
}
.list-items {
  margin-bottom: 1.6rem;
  list-style: none;
  padding-left: 0;
}
.list-items li {
  display: flex;
  align-items: center;
  position: relative;
  color: #595c73;
}
.list-items li.is-active {
  color: #303141;
}
.list-items li :global(.ud-icon) {
  margin-right: 0.8rem;
}
.list {
  padding: 2.4rem 2.4rem 3.2rem 2.4rem;
  display: flex;
  flex-direction: column;
  flex-basis: 32rem;
}
.list:last-of-type {
  padding-top: 0;
}
.refund-notice {
  padding: 0;
  margin: 2.4rem 0;
}
@media (min-width: 43.8125rem) {
  .comparison-lists {
    display: flex;
    flex-grow: 2;
  }
  .header {
    flex-basis: 33%;
  }
  .unit-title {
    text-align: left;
  }
  .view-more-button {
    display: inline-flex;
  }
  .list:last-of-type {
    padding-top: 2.4rem;
  }
}
@media (min-width: 61.3125rem) {
  .header {
    border-bottom: none;
  }
  .wrapper {
    display: flex;
  }
  .unit-title {
    margin-top: 0;
  }
  .list {
    padding: 2.4rem;
  }
}
@media (min-width: 75.0625rem) {
  .header {
    padding-right: 3.2rem;
  }
}
