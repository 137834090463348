.skeleton-title {
  height: 2.4rem;
  background-color: #d1d2e0;
  margin-bottom: 0.8rem;
}
.skeleton-title + .skeleton-title {
  margin-bottom: 1.6rem;
}
.skeleton-unit .title {
  height: 2.4rem;
  width: 100%;
  background-color: #d1d2e0;
  margin-bottom: 1.6rem;
}
.skeleton-unit .carousel {
  display: flex;
  overflow: hidden;
}
.skeleton-unit .course-container {
  display: flex;
  flex-direction: column;
  min-width: 25rem;
  margin-right: 1.6rem;
}
.skeleton-unit .course-image-lg {
  height: 14.8rem;
  background-color: #d1d2e0;
  margin-bottom: 1.6rem;
}
.skeleton-unit .info-container {
  flex-grow: 1;
}
.skeleton-unit .skeleton-card {
  display: flex;
}
.skeleton-unit .skeleton-card + .skeleton-card {
  margin-top: 1.6rem;
}
.skeleton-unit .course-info {
  width: 100%;
  height: 1.4rem;
  background-color: #d1d2e0;
}
.skeleton-unit .course-info + .course-info {
  margin-top: 0.4rem;
}
.skeleton-spacing {
  margin: 1.6rem 0;
}
.skeleton-unit + .skeleton-unit {
  margin-top: 2.4rem;
}
