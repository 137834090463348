.lab-icon {
  height: 3.2rem;
  width: 3.2rem;
  padding: 0.5rem;
  border-radius: 50%;
  background: #5022c3;
  color: #fff;
}
.completed {
  color: #303141;
  background: #d1d2e0;
}
