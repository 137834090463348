@container ud-content-area (min-width: @content-area-sm-min) {
  /* stylelint-disable-next-line max-line-length */
  grid-auto-columns: calc((100% - (4 - 1) * 1.6rem) * (3 / 4) + (3 - 1) * 1.6rem);
}@container ud-content-area (min-width: @content-area-md-min) {
  /* stylelint-disable-next-line max-line-length */
  grid-auto-columns: calc((100% - (12 - 1) * 1.6rem) * (6 / 12) + (6 - 1) * 1.6rem);
}@container ud-content-area (min-width: @content-area-xl-min) {
  /* stylelint-disable-next-line max-line-length */
  grid-auto-columns: calc((100% - (12 - 1) * 1.6rem) * (4 / 12) + (4 - 1) * 1.6rem);
}@container ud-content-area (min-width: @content-area-xl-min) {
  /* stylelint-disable-next-line max-line-length */
  grid-auto-columns: calc((100% - (9 - 1) * 1.6rem) * (4 / 9) + (4 - 1) * 1.6rem);
}@container ud-content-area (min-width: @content-area-xl-mid) {
  /* stylelint-disable-next-line max-line-length */
  grid-auto-columns: calc((100% - (9 - 1) * 1.6rem) * (3 / 9) + (3 - 1) * 1.6rem);
}@container ud-content-area (min-width: @content-area-sm-min) {
  grid-auto-columns: calc((100% - (2 - 1) * 1.6rem) / 2);
}@container ud-content-area (min-width: @content-area-md-min) {
  grid-auto-columns: calc((100% - (3 - 1) * 1.6rem) / 3);
}@container ud-content-area (min-width: @content-area-lg-min) {
  grid-auto-columns: calc((100% - (4 - 1) * 1.6rem) / 4);
}@container ud-content-area (min-width: @content-area-sm-min) {
  grid-auto-columns: calc((100% - (2 - 1) * 1.6rem) / 2);
}@container ud-content-area (min-width: @content-area-md-min) {
  grid-auto-columns: calc((100% - (3 - 1) * 1.6rem) / 3);
}@container ud-content-area (min-width: @content-area-lg-min) {
  grid-auto-columns: calc((100% - (4 - 1) * 1.6rem) / 4);
}@container ud-content-area (min-width: @content-area-xl-min) {
  grid-auto-columns: calc((100% - (5 - 1) * 1.6rem) / 5);
}.take-assessment-card-container {
  position: relative;
  border: 1px solid #d1d2e0;
  display: flex;
  flex-direction: row;
  height: 17.2rem;
  padding: 1.6rem;
}
@media (max-width: 75rem) {
  .take-assessment-card-container {
    height: 18.9rem;
  }
}
.take-assessment-card-container:hover {
  background-color: #f6f7f9;
}
.take-assessment-card-container-pp {
  height: 15rem;
}
.take-assessment-info {
  color: #595c73;
  flex: 1;
  padding: 0 0.8rem;
  display: flex;
  flex-direction: column;
  min-width: 0;
  justify-content: space-between;
}
.take-assessment-card-info-title {
  display: block !important;
  /* stylelint-disable-next-line value-no-vendor-prefix */
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  height: 4rem;
}
@media (max-width: 75rem) {
  .take-assessment-card-info-title {
    display: block !important;
    /* stylelint-disable-next-line value-no-vendor-prefix */
    display: -webkit-box !important;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    height: 6rem;
  }
}
.take-assessment-card-info-title a {
  color: #303141;
}
.take-assessment-card-info-title a::after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}
.take-assessment-card-info-container {
  flex: 1;
  margin-top: 0.4rem;
}
.take-assessment-card-info-line {
  display: flex;
}
.info-number-of-questions {
  margin-top: 0.4rem;
  display: flex;
  align-items: center;
}
.info-icon {
  margin-right: 0.4rem;
}
.info-content {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 0.8rem;
  color: #303141;
}
.info-container {
  flex: 1;
}
.info-link {
  color: #6d28d2;
}
.more-menu-button {
  padding: 0.8rem 0.4rem 0 0;
  position: absolute;
  right: 0;
  top: 0;
}
.ud-assessment-badge-container {
  display: flex;
  align-items: center;
  gap: 0.8rem;
}
