.student-quote {
  background-color: #f6f7f9;
  display: flex;
  flex-direction: column;
  padding: 2.4rem;
  position: relative;
}
.text {
  margin: 0.8rem 0;
}
.title {
  margin-top: 1.6rem;
}
@media (min-width: 37.5625rem) {
  .content {
    margin-left: 2.4rem;
  }
  .student-quote {
    flex-direction: row;
    align-items: flex-start;
  }
  .quote {
    width: 6.4rem;
    height: 5.8rem;
  }
  .text {
    font-size: clamp(1.7rem, calc(1.7rem + (1.8 - 1.7) * calc((100vw - 36rem) / (144 - 36))), 1.8rem);
    margin: 0.8rem 0 1.6rem 0;
  }
  .title {
    margin-top: 0;
  }
}
