@container ud-content-area (min-width: @content-area-sm-min) {
  /* stylelint-disable-next-line max-line-length */
  grid-auto-columns: calc((100% - (4 - 1) * 1.6rem) * (3 / 4) + (3 - 1) * 1.6rem);
}@container ud-content-area (min-width: @content-area-md-min) {
  /* stylelint-disable-next-line max-line-length */
  grid-auto-columns: calc((100% - (12 - 1) * 1.6rem) * (6 / 12) + (6 - 1) * 1.6rem);
}@container ud-content-area (min-width: @content-area-xl-min) {
  /* stylelint-disable-next-line max-line-length */
  grid-auto-columns: calc((100% - (12 - 1) * 1.6rem) * (4 / 12) + (4 - 1) * 1.6rem);
}@container ud-content-area (min-width: @content-area-xl-min) {
  /* stylelint-disable-next-line max-line-length */
  grid-auto-columns: calc((100% - (9 - 1) * 1.6rem) * (4 / 9) + (4 - 1) * 1.6rem);
}@container ud-content-area (min-width: @content-area-xl-mid) {
  /* stylelint-disable-next-line max-line-length */
  grid-auto-columns: calc((100% - (9 - 1) * 1.6rem) * (3 / 9) + (3 - 1) * 1.6rem);
}@container ud-content-area (min-width: @content-area-sm-min) {
  grid-auto-columns: calc((100% - (2 - 1) * 1.6rem) / 2);
}@container ud-content-area (min-width: @content-area-md-min) {
  grid-auto-columns: calc((100% - (3 - 1) * 1.6rem) / 3);
}@container ud-content-area (min-width: @content-area-lg-min) {
  grid-auto-columns: calc((100% - (4 - 1) * 1.6rem) / 4);
}@container ud-content-area (min-width: @content-area-sm-min) {
  grid-auto-columns: calc((100% - (2 - 1) * 1.6rem) / 2);
}@container ud-content-area (min-width: @content-area-md-min) {
  grid-auto-columns: calc((100% - (3 - 1) * 1.6rem) / 3);
}@container ud-content-area (min-width: @content-area-lg-min) {
  grid-auto-columns: calc((100% - (4 - 1) * 1.6rem) / 4);
}@container ud-content-area (min-width: @content-area-xl-min) {
  grid-auto-columns: calc((100% - (5 - 1) * 1.6rem) / 5);
}.title-wrapper {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 0;
}
@media (min-width: 37.5625rem) {
  .title-wrapper :global(.ud-btn) {
    margin-left: 1.6rem;
  }
}
.bottom-margin {
  margin-bottom: 1.6rem;
}
.secondary-text {
  display: flex;
  align-items: center;
  margin-bottom: 1.6rem;
  color: #595c73;
}
.secondary-text :global(.ud-badge) {
  margin-right: 0.8rem;
}
