.skeleton-fixed {
  width: 17.3rem;
}
.skeleton-flexible {
  max-width: 37.5rem;
  min-width: 17.3rem;
}
.line {
  height: 1.6rem;
}
.title {
  height: 3.2rem;
  margin-bottom: 0.4rem;
}
.line + .line {
  margin-top: 0.4rem;
}
.line:last-child {
  width: 54%;
}
.skeleton-small,
.skeleton-large {
  display: flex;
  min-width: 25.6rem;
  max-width: 55.2rem;
}
.skeleton-small .image,
.skeleton-large .image {
  width: 6.4rem;
  height: 6.4rem;
  margin-right: 0.8rem;
}
.skeleton-medium .image {
  max-width: 100%;
  padding-bottom: 56.25%;
  margin-bottom: 0.8rem;
}
@media (min-width: 37.5625rem) {
  .skeleton-large {
    display: flex;
    min-width: 50.8rem;
    max-width: 129.2rem;
  }
  .skeleton-large .image {
    width: 26.5rem;
    height: 14.5rem;
    margin-right: 0.8rem;
  }
}
