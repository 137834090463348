.related-categories-margin-sm {
  color: #595c73;
  margin-bottom: 0.8rem;
}
@media (max-width: 37.5rem) {
  .related-categories-margin-sm {
    color: #303141;
    margin-bottom: 1.6rem;
    max-width: 80rem;
  }
}
.related-categories-margin-lg {
  color: #595c73;
  margin-bottom: 4.8rem;
}
@media (max-width: 37.5rem) {
  .related-categories-margin-lg {
    color: #303141;
    margin-bottom: 3.2rem;
    max-width: 80rem;
  }
}
p[data-purpose='topic-enrollment-stats'] + .primary-unit {
  margin-top: 2.4rem !important;
}
.secondary-heading {
  margin: 0 0 1.6rem 0;
}
.secondary-description {
  margin-bottom: 2.4rem;
}
.secondary-description p {
  margin-bottom: 1.6rem;
}
.section-container {
  margin-top: 4.8rem;
  padding-top: 2.4rem;
}
.refund-notice {
  margin-bottom: 2.4rem;
}
.title {
  margin-bottom: 0.8rem;
}
.title-skeleton.title-skeleton {
  width: 50%;
  height: 4rem;
  margin-bottom: 1.6rem;
}
@media (max-width: 37.5rem) {
  .title-skeleton.title-skeleton {
    width: 75%;
    height: 3.2rem;
  }
}
.learn-more-section-container {
  border-top: 1px solid #d1d2e0;
  padding-top: 4.8rem;
  margin-top: 4.8rem;
}
@media (max-width: 37.5rem) {
  .learn-more-section-container {
    border-top: none;
    padding-top: 0;
  }
}
div[data-purpose='value-props'] + .learn-more-section-container {
  border-top: none;
  padding-top: 0;
}
.learn-more-section {
  max-width: 70rem;
  margin: 0 auto;
}
.career-track-link {
  margin-bottom: 1.6rem;
}
.alternate-headline__subtitle {
  margin-top: 0.8rem;
  color: #9194ac;
}
.expanded-course-card {
  width: 101%;
}
.certification-bundle-unit-wrapper {
  border: 1px solid #d1d2e0;
  border-radius: 0.4rem;
}
