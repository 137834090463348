@container ud-content-area (min-width: @content-area-sm-min) {
  /* stylelint-disable-next-line max-line-length */
  grid-auto-columns: calc((100% - (4 - 1) * 1.6rem) * (3 / 4) + (3 - 1) * 1.6rem);
}@container ud-content-area (min-width: @content-area-md-min) {
  /* stylelint-disable-next-line max-line-length */
  grid-auto-columns: calc((100% - (12 - 1) * 1.6rem) * (6 / 12) + (6 - 1) * 1.6rem);
}@container ud-content-area (min-width: @content-area-xl-min) {
  /* stylelint-disable-next-line max-line-length */
  grid-auto-columns: calc((100% - (12 - 1) * 1.6rem) * (4 / 12) + (4 - 1) * 1.6rem);
}@container ud-content-area (min-width: @content-area-xl-min) {
  /* stylelint-disable-next-line max-line-length */
  grid-auto-columns: calc((100% - (9 - 1) * 1.6rem) * (4 / 9) + (4 - 1) * 1.6rem);
}@container ud-content-area (min-width: @content-area-xl-mid) {
  /* stylelint-disable-next-line max-line-length */
  grid-auto-columns: calc((100% - (9 - 1) * 1.6rem) * (3 / 9) + (3 - 1) * 1.6rem);
}@container ud-content-area (min-width: @content-area-sm-min) {
  grid-auto-columns: calc((100% - (2 - 1) * 1.6rem) / 2);
}@container ud-content-area (min-width: @content-area-md-min) {
  grid-auto-columns: calc((100% - (3 - 1) * 1.6rem) / 3);
}@container ud-content-area (min-width: @content-area-lg-min) {
  grid-auto-columns: calc((100% - (4 - 1) * 1.6rem) / 4);
}@container ud-content-area (min-width: @content-area-sm-min) {
  grid-auto-columns: calc((100% - (2 - 1) * 1.6rem) / 2);
}@container ud-content-area (min-width: @content-area-md-min) {
  grid-auto-columns: calc((100% - (3 - 1) * 1.6rem) / 3);
}@container ud-content-area (min-width: @content-area-lg-min) {
  grid-auto-columns: calc((100% - (4 - 1) * 1.6rem) / 4);
}@container ud-content-area (min-width: @content-area-xl-min) {
  grid-auto-columns: calc((100% - (5 - 1) * 1.6rem) / 5);
}:global(body.ud-keyboard-navigation-in-use) .lab-card-container h3:focus-within {
  /* stylelint-disable unit-disallowed-list */
  box-shadow: 0 0 0 2px var(--color-white), 0 0 0 4px var(--color-purple-400), 0 0 2px 5px var(--color-purple-150);
  /* stylelint-enable unit-disallowed-list */
}
.lab-card-container {
  border: 1px solid #d1d2e0;
  display: flex;
  flex-direction: row;
  height: 17.2rem;
  padding: 1.6rem;
}
@media (max-width: 75rem) {
  .lab-card-container {
    height: 18.9rem;
  }
}
.lab-card-container h3[data-purpose='lab-title-url'] a {
  color: #303141;
}
.lab-card-container h3[data-purpose='lab-title-url'] a:focus {
  outline: none !important;
}
.lab-card-container h3[data-purpose='lab-title-url'] a[href]::after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.lab-icon-container {
  width: 12.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #c0c4fc;
}
.lab-info {
  color: #595c73;
  flex: 1;
  padding: 0 0.8rem;
  display: flex;
  flex-direction: column;
  min-width: 0;
  justify-content: space-between;
}
.title {
  display: block !important;
  /* stylelint-disable-next-line value-no-vendor-prefix */
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  height: 4rem;
}
.title-one-liner {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.lab-owner {
  margin-top: 0.4rem;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.info-completion-time {
  margin-top: 0.4rem;
  display: flex;
  align-items: center;
}
.info-number-of-questions {
  display: flex;
}
.info-icon {
  margin-right: 0.4rem;
}
.info-content {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 0.8rem;
  color: #303141;
}
.completion-date {
  color: #595c73;
  display: inline-flex;
  font-size: 1.2rem;
  margin-top: 0.4rem;
}
.popover-button {
  display: inline-flex;
  border-left: 1px solid #d1d2e0;
  margin-left: 1.6rem;
  padding-left: 1.6rem;
}
